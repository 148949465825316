import { navigateTo } from 'nuxt/app'
import { defineNuxtPlugin } from '#imports'
import { useGuestStore } from '~/store/guest'

export default defineNuxtPlugin((nuxtApp: any) => {
  const api = $fetch.create({
    onRequest({ options }) {
      const { guest } = useGuestStore()

      if (guest.loggedInToken || guest.token) {
        if (options.headers) {
          return
        }
        options.headers ||= {}
        options.headers['x-authorization-obo'] = `Bearer ${guest.loggedInToken || guest.token
          }`
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        const { reset: logout } = useGuestStore()
        logout()
        await nuxtApp.runWithContext(() => navigateTo('/welcome'))
      }
    },
  })

  // Expose to useNuxtApp().$api
  return {
    provide: {
      api,
    },
  }
})
