import { defineNuxtRouteMiddleware } from 'nuxt/app'
import { useGuestStore } from '~/store/guest'
import { useGuestCartStore } from '~/store/guestCart'

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.meta.cart !== false) {
    const { gift, guest } = useGuestStore()
    const { cart, loadCart } = useGuestCartStore()

    if (gift && guest?.cartId && !cart) {
      await loadCart(guest.cartId)
    }
  }
})
