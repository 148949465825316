import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app'
import { useGuestStore } from '~/store/guest'
import { useGuestCartStore } from '~/store/guestCart'

export default defineNuxtRouteMiddleware(async (to) => {
  const { gift: giftQueryParam } = to.query
  const guestStore = useGuestStore()
  const guestCartStore = useGuestCartStore()

  if (to.path === '/welcome') {
    guestStore.reset()
    guestCartStore.reset()
    return
  }

  if (
    !guestStore.gift?.id ||
    !guestStore.guest?.token ||
    (giftQueryParam && guestStore.gift?.id !== String(giftQueryParam))
  ) {
    guestStore.reset()
    guestCartStore.reset()

    if (giftQueryParam) {
      await guestStore.loadGift(giftQueryParam as string)
      if (!guestStore.gift?.id) {
        return navigateTo('/welcome')
      }
      await guestStore.loginGuestUser()
      if (guestStore.requestError) {
        return navigateTo('/welcome')
      }

      if (guestStore.guest.token) {
        const cartId = await guestCartStore.createCart()
        if (cartId) {
          guestStore.guest.cartId = cartId
        }
      }
    } else {
      return navigateTo('/welcome')
    }
  } else if (guestStore.gift?.id && !giftQueryParam) {
    return navigateTo({
      path: to.path,
      query: { ...to.query, gift: guestStore.gift?.id },
    })
  }
})
